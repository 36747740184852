#progress-reviews {
  .main-content {
    display: block !important;
    margin: 0 !important;
    div {
      margin: 0 !important;
    }
    button {
      margin: 10px;
    }
  }
}
