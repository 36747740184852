.main-content {
  margin: 80px auto 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .notes {
    width: 100%;
    a {
      text-decoration: underline;
    }
  }
  .form-container {
    width: 48%; /* Adjust as needed, this leaves a small gap between the containers */
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    h2 {
      margin-bottom: 20px;
    }
    p {
      margin: 20px 0;

      a {
        text-decoration: underline;
      }
    }
    .code-container {
      display: flex;

      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      margin-top: 20px;
    }

    .code-display {
      font-size: 24px;
    }

    .icon-buttons {
      display: flex;
      gap: 10px;
    }

    .toggle-button,
    .copy-button {
      border: none;
      cursor: pointer;
      font-size: 20px;
    }

    /* Add any additional styles or customizations as needed */
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input,
  select {
    width: calc(100% + 10px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  select {
    width: 100%;
  }

  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  button:hover {
    background-color: #0056b3;
  }
}
.success-message {
  color: green;
}

.error-message {
  color: red;
}
