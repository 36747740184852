@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* Proxima Nova - Light */
@font-face {
  font-display: swap;
  font-family: "Proxima Nova Light";
  src: url(./fonts/proxima-nova-reg.woff2) format("woff2"),
    url(./fonts/proxima-nova-reg.woff) format("woff"),
    url(./fonts/proxima-nova-reg.otf) format("opentype");
  font-style: normal;
  font-weight: 200;
}

/* Proxima Nova - Regular */
@font-face {
  font-display: swap;
  font-family: "Proxima Nova";
  src: url(./fonts/proxima-nova-reg.woff2) format("woff2"),
    url(./fonts/proxima-nova-reg.woff) format("woff"),
    url(./fonts/proxima-nova-reg.otf) format("opentype");
  font-style: normal;
  font-weight: 400;
}

/* Proxima Nova - Bold */
@font-face {
  font-display: swap;
  font-family: "Proxima Nova Bold";
  src: url(./fonts/proxima-nova-bold.woff2) format("woff2"),
    url(./fonts/proxima-nova-bold.woff) format("woff"),
    url(./fonts/proxima-nova-bold.otf) format("opentype");
  font-style: normal;
  font-weight: 600;
}

/* Futura - Book */
@font-face {
  font-display: swap;
  font-family: "Futura";
  src: url(./fonts/futura-book.woff2) format("woff2"),
    url(./fonts/futura-book.woff) format("woff"),
    url(./fonts/futura-book.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

/* Futura - Bold */
@font-face {
  font-display: swap;
  font-family: "Futura";
  src: url(./fonts/futura-bold.woff2) format("woff2"),
    url(./fonts/futura-bold.woff) format("woff"),
    url(./fonts/futura-bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}

// Colors
$darkgreen: #274548;
$lightGreen: #9fe2dd;
$darkergreen: rgb(18, 33, 34);
$lightblue: #9fe2dd;
$beige: #fbf6ee;
$orange: #edc034;
$darkerOrange: #d8a200;
$darkOrangeTransparent: rgba(216, 162, 0, 0.7);
$lightgrey: rgb(219, 219, 219);

$fontSize: 18px;
$headerFont: "Futura", sans-serif;
$bodyFont: "Proxima Nova", sans-serif;
$bodyFontLight: "Proxima Nova Light", sans-serif;
// $headerFont: 'roboto mono','futura-pt-bold',sans-serif;
// $bodyFont: 'VT323',monospace;
$breakpoint-tablet: 768px;
$breakpoint-phone: 600px;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

ul {
  margin: 30px;
}

@mixin flexh {
  display: flex;
  flex-flow: row wrap;
}

@mixin flexhc {
  @include flexh;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

@mixin flexv {
  display: flex;
  flex-flow: column wrap;
}

@mixin flexvc {
  @include flexv;
  align-content: center;
  justify-content: center;
}

@mixin approve {
  background-color: rgb(80, 221, 15);
}

@mixin todo {
  background-color: rgb(216, 61, 61);
}

body {
  color: $darkgreen;
  font-size: $fontSize;
  font-family: $bodyFont;
  margin: 0;
  padding: 0;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  a {
    color: #b8b7ad;
    padding-bottom: 10px;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#page-container {
  .loading {
    text-align: center;
    margin-top: 20px;
  }

  #signIn,
  #landing,
  #forget,
  #signup,
  #launchpad,
  #universe,
  #classes,
  #new-class,
  #universe-publish {
    background-color: $darkgreen;
    color: #fff;
    h1 {
      font-size: 6rem;
      color: #fff;
      text-shadow: rgb(53, 173, 185) 2px 1px 0px;
      text-align: center;
    }
  }
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headerFont;
  padding: 0;
  margin: 0;
}

#landing h1 {
  margin-top: -5rem;
  margin-bottom: 0.5rem;
}

p {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.6rem;
}

a {
  text-decoration: none;
  outline: none;
  color: $darkgreen;
}

button {
  font-size: $fontSize;
  font-family: $bodyFont;
}

.center {
  text-align: center;
}

.title {
  font-size: 1.4rem;
  margin-top: -30px;
  font-family: $headerFont;
}

img.pixel {
  // image-rendering:pixelated;
}

#page-container {
  display: grid;
  height: 100%;
  grid-template-rows: 70px 1fr;
  position: relative;
  .loading {
    text-align: center;
  }
}

#header {
  background-color: #fff;
  width: 100%;
  display: flex;
  .menu {
    width: 100%;
    background-color: #fff;
    position: fixed;
    padding-bottom: 70px;
    border-bottom: 1px solid $lightgrey;

    z-index: 99;
  }

  .logo {
    @include flexvc;
    margin: 0 40px;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
  .nav {
    @include flexh;
    line-height: 70px;
    width: 100%;
    height: 70px;
    justify-content: flex-end;
    .signIn {
      color: #fff;
      text-align: center;
      border-radius: 8px;
      transition: background-color 2s;
      background-color: $orange;
      padding: 10px 20px;

      &:hover {
        background-color: $darkerOrange;
      }
    }
    > a {
      margin: 0 20px;
      &:hover {
        border-top: 4px solid $lightGreen;
      }
      &:not(.logo) {
        text-align: right;
      }
    }
    .hideMenu {
      display: none;
    }
    .showMenu {
      @include flexv;
      text-align: left;
      line-height: 1rem;
      background-color: $lightblue;
      z-index: 9999;
      position: absolute;
      top: 70px;
      margin: 0 40px 0 0;
      button {
        border-radius: 0;
        padding: 5px 20px;
        width: 100%;
        background-color: $darkerOrange;
      }
      a {
        padding: 10px 40px;
        text-align: left;
        z-index: 1;
      }
      a:hover {
        background-color: $orange;
      }
    }
    .dropdown {
      cursor: pointer;
      text-align: right;
      height: 70px;
      z-index: 99;
      margin: 0 40px;
      #menu {
        @include flexh;
        justify-content: flex-end;
        padding: 0 5px;
        &.highlight {
          border-top: 4px solid $lightGreen;
        }
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
          margin-right: 10px;
          margin-top: 15px;
        }
        &:after {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $darkgreen;
          content: "";
          cursor: pointer;
          margin: 35px 0 0 5px;
        }
        &:hover {
          border-top: 4px solid $lightGreen;
        }
      }
    }
  }
}

button,
.button {
  border-radius: 4px;
  align-content: center;
  justify-content: center;
  flex-flow: column wrap;
  margin: 0 auto;
  border: 0;
  outline: 0;
  width: 100px;
  padding: 6px 10px;
  grid-column: 10;
  background-color: $orange;
  cursor: pointer;
  transition: background-color 2s;
  a {
    color: #fff;
  }
  &:hover {
    background-color: $darkerOrange;
  }
}

button {
  background-color: $orange;
}

.main {
  .required {
    color: red;
    font-weight: bold;
    font-size: 18px;
  }
  .error {
    text-align: center;
  }
  a {
    font-weight: bold;
    color: $orange;
  }
  form {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    margin: 0 auto;
    input,
    button {
      display: block;
      margin: 10px auto;
      padding: 10px 0;
      font-family: $bodyFont;
      width: 250px;
      border: 0;
      outline: 0;
    }
    input {
      padding-left: 10px;
    }
    input[type="checkbox"] {
      display: inline-block;
      width: auto;
      margin: 20px 10px;
    }
    label {
      color: $orange;
      display: inline-block;
    }
    button {
      width: 260px;
    }
    p {
      color: $lightblue;
      background-color: black;
    }
  }
}

#landing,
#signup,
#signIn,
#forget {
  background: url("images/bg.png") no-repeat;
  background-size: cover;
  background-position: 10% 50%;
  #rocket {
    top: 50px;
  }
  .main {
    grid-column: span 12;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    .button {
      color: #fff;
      padding: 10px;
    }
  }
}

#signIn {
  form {
    p {
      max-width: 600px;
    }
  }
  .roles {
    display: flex;
    flex-flow: row wrap;
  }
}

.launch {
  background-color: $orange;
  color: #fff;
}

#clazz,
#untutorial,
#new-project,
#jetfuel,
#question,
#progress-reviews {
  .main {
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
    background-color: #fff;
    color: $darkgreen;
    .sidebar {
      color: #fff;
      background-color: $beige;
      // padding: 60px 30px;
      background-color: $darkgreen;
      word-wrap: break-word;
      padding: 60px 30px;
      > div {
        margin-bottom: 40px;
      }
      button {
        padding: 0 6px;
        width: auto;
        margin: 4px auto;
      }
      h1 {
        font-size: 1.4rem;
        color: $darkgreen;
        text-shadow: none;
      }
      p {
        margin: 0;
        padding: 0;
      }
      span {
        font-size: 0.8rem;
      }
      .container {
        position: relative;
        .description {
          margin-top: 20px;
        }
        p {
          margin-top: 20px;
        }
      }
      select {
        margin-right: 5px;
      }
      .field {
        line-height: 1.8rem;
        &.draft > div {
          background-color: red;
        }
        &.approved {
          div {
            background-color: #00b700;
          }
        }
        &.level {
          font-size: 1.6rem;
        }
        button {
          font-size: 1rem;
          padding: 2px 6px;
          background-color: $lightGreen;
          color: $darkgreen;
          display: inline;
        }
        &.display-name {
          font-size: 2rem;
          margin-top: 10px;
          font-family: $headerFont;
          line-height: 2rem;
          position: relative;
          background-color: $darkgreen;
          color: #fff;
          text-align: center;
          .edit {
            top: 0;
            right: 30px;
          }
        }
      }
      .content {
        border-radius: 4px;
        .field {
          position: relative;
          text-align: center;
          padding: 0 30px;
          h1 {
            font-size: 2rem;
            color: $darkgreen;
          }
          img {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .container {
          margin: 20px 0;
        }
      }
    }
    .main-content {
      color: $darkgreen;
      position: relative;
      padding: 60px;
      position: relative;
      .approve {
        text-align: center;
        margin-top: 20px;
        div {
          color: $orange;
          position: relative;
          display: inline;
          padding-right: 20px;
        }
      }
      .container {
        .field {
          position: relative;
          margin: 0;
          background-color: $beige;
          padding: 0 40px;
          border-radius: 8px;
          font-weight: bold;
          .edit {
            right: 2px;
            top: 5px;
          }
        }
      }
      .content {
        > div {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          .field {
            position: 2px solid red;

            img {
              width: 25px;
              height: auto;
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          h4 {
            p {
              margin: 0;
              display: inline;
              padding: 0;
            }
          }
        }
      }
    }
    .quill {
      color: $darkgreen;
      // max-width: 525px;
      border: 1px solid #d1d1d1;
      border-radius: 4px;
    }
  }
}

#launchpad,
#universe,
#clazz {
  .filter {
    div {
      width: 100%;
    }
    button {
      margin-bottom: 5px;
      width: 100%;
      position: relative;
      background-color: #fff;
      transition: normal;
      display: flex;
    }
    .f {
      background-color: #eeefef;
      // &:after {
      //   content: "x";
      //   position: absolute;
      //   right: 10px;
      // }
    }
  }
  .main {
    color: #fff;
    display: grid;
    display: grid;
    grid-template-columns: 250px 5fr;
    height: 100%;
    .main-content {
      background-color: $darkgreen;
      color: #fff;
      .search {
        padding: 10px;
        width: 50%;
        display: block;
        margin: 0 auto;
        border: 3px solid $orange;
        outline: 0;
        border-radius: 4px;
        margin: 40px auto;
      }
      .items {
        @include flexhc;
        h1 {
          margin: 20px 0;
        }
        .card {
          background-color: #fff;
          border-radius: 8px;
          color: $darkgreen;
          width: 28%;
          margin: 2%;
          position: relative;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
            0 1px 2px 0 rgba(0, 0, 0, 0.24);
          padding-bottom: 30px;
          img {
            width: 100%;
            height: 130px;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
              0 1px 2px 0 rgba(0, 0, 0, 0.24);
          }
          > div {
            padding: 40px 20px;
            font-size: 1rem;
            font-family: $bodyFont;
            font-weight: 300;
            min-height: 250px;
            h2 {
              margin-bottom: 20px;
            }
            p {
              line-height: 1.6rem;
            }
            button {
              background-color: $darkgreen;
              color: #fff;
              position: absolute;
              bottom: 10px;
              left: 0;
              right: 0;
              width: 50%;
            }
          }
        }
      }
    }
    .sidebar {
      background-color: #fff;
      border-right: 5px solid $orange;
      width: 250px;
      h2 {
        color: $darkgreen;
        margin-bottom: 20px;
      }
      button {
        transition: background-color 2s;
        &:hover {
          background-color: #eeefef;
        }
      }
    }
  }
}

#classes {
  h1 {
    margin-top: 20px;
  }
  h2 {
    word-break: break-word;
  }
  .button {
    display: block;
    margin: 0 auto;
    color: $darkgreen;
    text-align: center;
  }
  .main {
    @include flexhc;
    .card {
      color: $darkgreen;
      width: 200px;
      background-color: white;
      margin: 40px;
      text-align: center;
      border-radius: 8px;
      position: relative;
      padding-bottom: 50px;
      min-height: 300px;
      div {
        padding-bottom: 10px;
        font-weight: normal;
      }
      h4 {
        margin-top: 30px;
        word-break: break-word;
        width: 100%;
      }
      h5 {
        margin: 10px 0;
      }
      button {
        background-color: $darkgreen;
        color: #fff;
        bottom: 10px;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 50%;
      }
      img {
        width: 100%;
        height: 130px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
          0 1px 2px 0 rgba(0, 0, 0, 0.24);
      }
    }
  }
}

#clazz {
  .main {
    .main-content {
      h1 {
        text-align: center;
      }
      .items {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .card {
          transition: 2s;
          margin: 20px auto;
          width: 200px;
          height: 170px;
          padding: 10px;
          text-align: center;
          font-family: $headerFont;
          text-transform: capitalize;
          background-size: contain;
          color: #fff;
          border-radius: 8px;
          position: relative;
          background-color: $lightGreen;
          img {
            object-fit: contain;
            background-color: #f7f7f7;
            padding-top: 5px;
          }
          span {
            position: absolute;
            font-size: 14px;
            bottom: 0;
            background-color: $lightGreen;
            color: $darkgreen;
            left: 0;
            right: 0;
            bottom: 5px;
            margin: 0 auto;
            &:hover {
              // background-color: #61bbb5;
            }
          }
        }
      }
      .console {
        .showConsole {
          display: block;
          margin: 40px auto;
        }
        .hideConsole {
          display: none;
        }

        button {
          display: flex;
        }
      }
    }
    .sidebar {
      width: 250px;
      padding: 20px;
      color: $darkgreen;
      text-align: center;
      .avatar {
        border: 0;
        width: 100%;
        height: 200px;
        padding: 0;
        label {
          height: 100%;
        }
        .upload {
          width: 100%;
        }
        img {
          position: static;
          width: 100%;
          height: 100%;
        }
      }

      .field {
        .editor-button {
          background-color: $darkgreen;
          color: #fff;
        }
        .quill {
          button {
            background-color: transparent;
          }
        }
      }
    }
  }
}
// #clazz {
//   .main {
//   .main-content {
//     >div {
//       margin: 40px auto;
//       h3 {
//         margin-bottom: 20px;
//       }
//     }
//   }
//   .sidebar {
//     max-width: 300px;
//     padding: 20px;;
//     .avatar {
//       border:0;
//       width: 100%;
//       padding: 0;
//     }

//   }
// }
// }

#page-container {
  #launchpad {
    .main {
      h1 {
        width: 100%;
        font-size: 3rem;
      }
    }
  }
}

#untutorial,
#new-project {
  .blur {
    opacity: 0.5;
  }
  .thumbnail {
    height: auto;
    .add {
      text-align: left;
      margin: 20px 0 0;
      font-weight: bold;
    }
    .upload {
      border: 0;
      height: 20px;
      margin: 20px 0 0;
    }

    .replace {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .change {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
      background-color: #000;
      color: #fff;
    }
  }
  .workOnProject {
    h3 {
      text-align: center;
      border-top: 2px solid $darkgreen;
      background-color: $orange;
      padding: 10px;
      a {
        text-decoration: underline;
      }
    }
  }
  .iframe-off {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    border-radius: 50%;
    padding: 10px;
  }
  .iframe-on {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    .popup {
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      position: relative;
      width: 600px;
      iframe {
        width: 100%;
        height: 350px;
      }
      p {
        margin-bottom: 10px;
      }
      a {
        color: $orange;
        font-weight: bold;
        &.back,
        &.close {
          position: absolute;
          cursor: pointer;
          top: 20px;
          font-family: $headerFont;
          font-weight: bold;
          color: black;
        }
        &.back {
          left: 20px;
        }
        &.close {
          right: 20px;
        }
      }
      > div {
        padding: 20px;
        text-align: center;
      }
      iframe {
        margin-top: 20px;
      }
      button {
        background-color: $darkgreen;
        color: #fff;
        padding: 4px 20px;
        width: auto;
        margin: 10px auto;
      }
      .toggle-iframe {
        display: none;
      }
      &:before {
        content: "Try It Out!";
        display: block;
        background-color: $orange;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
    iframe {
      display: block;
      margin: 0 auto;
    }
  }
  .main {
    background-color: #f7f7f7;
    .addDelete {
      @include flexhc;
      button {
        flex-flow: row wrap;
      }
    }
    .del {
      margin: 40px auto;
      display: block;
      width: auto;
      background-color: $darkgreen;
      color: #fff;
    }
    .publish {
      width: auto;
      margin-top: 30px;
    }
    grid-template-columns: 2fr 1fr;
    border-top: 2px solid $darkgreen;
    padding: 0 5%;
    .scratch {
      grid-column: span 2;
      img {
        width: 130px;
      }
    }
    .sidebar {
      background-color: transparent;
      color: $darkgreen;
      margin-top: 20px;
      margin-right: 20px;
      padding-top: 30px;
      .tags {
        z-index: 9;
      }
      .filter-categories {
        a {
          display: block;
          margin: 20px 0;
        }
      }
      .quill {
        button {
          // background-color: #fff;
        }
      }
      .titleStatus {
        .title {
          margin-top: 0;
          font-family: $bodyFont;
          font-weight: bold;
          p {
            margin: 0;
            padding: 0;
            line-height: 2rem;
            font-size: 2rem;
            display: inline;
            font-family: $headerFont;
          }
        }
      }
      .container {
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        margin-bottom: 20px;
        @include flexv;
        .field {
          button {
            background-color: $darkgreen;
            color: #fff;
            display: block;
            margin-left: 0;
          }
          .quill {
            button {
              background-color: transparent;
              color: $darkergreen;
            }
          }
          input {
            border: 0;
            padding: 0;
            border-bottom: 2px solid $darkergreen;
            font-size: 1rem;
            width: 100%;
            &:focus {
              outline: 0;
            }
          }
        }
        .checkout {
          margin-left: 0;
          margin-top: 10px;
          padding: 10px 30px;
        }
      }
    }
    .main-content {
      .back {
        cursor: pointer;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }
      button {
        margin-bottom: 20px;
      }
      .status {
        > div {
          margin-left: 2px;
        }
      }
      padding-top: 30px;
      text-align: center;
      h3 {
        font-size: 2rem;
      }
      .thumbnail {
        width: auto;
        margin: 40px 0;
        img {
          width: auto;
        }
      }
      .url {
        margin: 0 0 40px;
        font-size: 1.4rem;
        h2 {
          margin-top: 20px;
        }
        input {
          padding: 8px;
          border: 4px solid $orange;
          border-radius: 4px;
          width: 50%;
          font-size: 1.5rem;
          &:focus {
            outline: 0;
          }
        }
        button {
          background-color: $darkgreen;
          color: #fff;
          width: auto;
          padding: 2px 6px;
          margin: 10px auto;
          font-size: 1rem;
          display: block;
        }
      }
      .steps {
        &:hover {
          // max-height: 800px;
          // overflow-y: auto;
        }
        .step {
          text-align: left;
          background-color: #fff;
          position: relative;
          width: 533px;
          margin: 20px auto 60px;
          border-bottom: 1px solid #ccc;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
          padding: 0 20px 60px;
          border-radius: 10px;
          .step-title {
            font-size: 2rem;
          }
          p {
            margin-top: 20px;
            // margin-right: 40px;
          }
          .no-button {
            font-size: 32px;
            button,
            .editor-button {
              display: none;
            }
          }
          .step-title {
            @include flexh;
            font-weight: 700;
            font-size: 2rem;
            padding-top: 10px;
            // word-break: break-all;
            font-family: $bodyFont;
            p {
              width: 100%;
            }
            div {
              img {
                width: 60px;
              }
            }
            .header {
              div {
                margin-bottom: 10px;
              }
              .editor-button {
                padding: 0 4px;
              }
              > div:not(.quill) {
                font-size: 2rem;
                margin: 0;
              }
              input {
                border: 0;
                border-bottom: 2px solid $darkgreen;
                &:focus {
                  outline: 0;
                }
              }
              button {
                width: auto;
                padding: 2px 10px;
              }
              .editor-button {
                width: auto;
                margin: 0;
                display: flex;
              }
            }
          }
          .step-content {
            margin-top: 20px;
            button {
              width: auto;
              display: block;
              margin: 0;
            }

            .done-button {
              position: absolute;
              top: 10px;
              right: 10px;
              border-radius: 50%;
              width: 60px;
              height: 60px;
              font-size: 12px;
              @include flexvc;
              &.todo {
                background-color: rgb(175, 23, 23);
                background: radial-gradient(
                  circle,
                  rgb(236, 99, 99),
                  rgb(247, 48, 48)
                );
                &:hover {
                  background: radial-gradient(
                    circle,
                    rgb(206, 73, 73),
                    rgb(247, 48, 48)
                  );
                }
              }
              &.pending {
                background-color: $orange;
                background: radial-gradient(circle, #e9dd33, $orange);
              }
              &.approved {
                background-color: rgb(80, 221, 15);
                background: radial-gradient(circle, #0bec3c, #11bd11);
              }
            }
          }
          .delete {
            position: absolute;
            bottom: -60px;
          }
          .comments {
            margin-top: 10px;
            background-color: $lightGreen;
            padding: 10px;
          }
          .field {
            > div {
              font-size: 1.2rem;
              font-weight: 300;
              text-align: left;
              margin-bottom: 10px;
            }
            iframe {
              margin-top: 20px;
              display: block;
              margin: 20px auto;
            }
          }
        }
      }
    }
  }
  .sidebar {
    iframe {
      display: none;
    }
  }
}

#untutorial {
  .thumbnail {
    width: 100%;
    margin: 0;
    margin-top: 40px;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    img,
    label {
      width: 100%;
      height: 400px;
      margin: 0;
      border: 0;
      object-fit: cover;
    }
    &.crop {
      height: 390px;
      overflow: hidden;
      border: 1px solid #ccc;
    }
    &.hero {
      height: 272px;
      border: 0;
      margin-top: 0;
      top: 0;
      img,
      label {
        height: 272px;
      }
    }
  }
  .status {
    display: flex;
    align-items: center;

    > div:not(.field) {
      margin: 5px;
      &.red {
        background-color: rgb(247, 48, 48);
        background: radial-gradient(circle, rgb(236, 99, 99), rgb(247, 48, 48));
      }
      &.blue {
        background-color: blue;
        background: radial-gradient(circle, rgb(17, 147, 233), rgb(4, 61, 136));
      }
    }
  }
}

#new-project {
  .main {
    @include flexhc;
    align-content: flex-start;
    margin: 0;
    padding: 0;
    border-top: 0;
    .main-content {
      .steps {
        .step {
          padding-bottom: 0;
          p {
            margin: 0;
          }
        }
      }
    }
    .toolbar {
      position: sticky;
      @include flexhc;
      width: 100%;
      height: 50px;
      top: 0;
      z-index: 9;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      text-align: center;
      button {
        width: auto;
        margin: 5px 10px 0;
        color: #fff;
        background-color: $darkgreen;
        width: 15%;
        transition: 2s;
        &:hover {
          background-color: $darkergreen;
        }
      }
    }
    .sidebar {
      padding-top: 30px;
      width: 300px;
      .container {
        .thumbnail {
          height: auto;
          border: 0;
          img {
            height: auto;
          }
          label {
            height: 50px;
          }
        }
        p {
          margin: 0;
        }
        > div {
          h4 {
            position: relative;
            text-align: left;
          }
          position: relative;
          margin-bottom: 10px;
        }
        .filter-categories {
          @include flexv;
          a {
            margin: 5px 0;
            cursor: pointer;
          }
        }
      }
    }
    .field {
      margin: 0;
      position: static;
      padding: 0;
      button {
        z-index: 2;
        top: 0;
        display: block;
        text-align: left;
        .quill {
        }
      }
    }
    .delete {
      bottom: 2px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}

#signin,
#forget,
#signup,
#signIn {
  background: url("images/bg2.png") no-repeat;
  background-size: cover;
  background-position: 10% 20%;
  #rocket {
    top: -40px;
    z-index: 0;
  }
}

#signup {
  labels {
    @include flexh;
  }
}

#resources {
  .toolbar {
  }
  .main {
    @include flexvc;
    margin: 80px auto;
    .title {
      margin: 40px auto;
    }
  }
  button {
    display: block;
    width: auto;
    margin: 20px auto;
  }
}
#rocket {
  background: url("images/roket.png") no-repeat;
  position: absolute;
  z-index: 99;
  width: 100px;
  height: 190px;
  background-size: contain;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#launchpad,
#universe,
#classes,
#universe-publish {
  color: $orange;
  a {
    display: inline-block;
    cursor: pointer;
    color: $orange;
  }
  .filter {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 30px auto;
    max-width: 600px;
    div {
      text-align: center;
      margin: 1rem;
    }
  }
  .filter-categories {
    text-align: center;
    margin-top: 20px;
    a {
      color: $darkgreen;
      background-color: $orange;
      padding: 5px;
      margin: 10px;
      display: inline;
      border-radius: 5px;
    }
  }
  .container {
    width: 50%;
    position: relative;
    margin: 0 auto;
    .field {
      .quill {
        background-color: #fff;
        h4 {
          margin: 20px 0;
        }
      }
      .edit {
        position: absolute;

        right: 0;
        top: 0;
      }
    }
  }
}

.status {
  margin: 0 auto;
}

.progress {
  .status {
    margin: 0;
  }
}

#untutorial,
#profile,
#progress-reviews {
  .fa {
    font-size: 1.6rem;
  }
  .fa-code {
    background-color: $darkgreen;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  .complete {
    text-align: center;
  }
  .yellow {
    color: $orange;
    text-shadow: 0 0 3px #ff0000, 0 0 5px #0000ff;
  }
  .green {
    color: rgb(80, 221, 15);
    text-shadow: 0 0 3px #046404, 0 0 5px #0bec3c;
  }
  .white {
    color: rgb(255, 255, 255);
    text-shadow: 0 0 3px $orange, 0 0 5px #dfb704;
  }
  .status {
    font-size: 1.2rem;
    .approved {
      // background-color: rgb(80, 221, 15);
      // background: radial-gradient(circle, #0bec3c, #0f9c0f);
      // width: 15px;
      // height: 15px;
      // border-radius: 50%;
      // display: inline-block;
      // margin-left: 5px;
    }
    .todo {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgb(247, 48, 48);
      background: radial-gradient(circle, rgb(236, 99, 99), rgb(247, 48, 48));
      display: inline-block;
      margin-left: 5px;
    }
  }
  .progress {
    .pending,
    .approved {
      color: $orange;
      text-shadow: 0 0 3px $orange, 0 0 5px #e9dd33;
    }
  }
}

#profile {
  #projects,
  #untutorials {
    .status {
      justify-content: center;
    }
  }
}

#simulator {
  background-color: #fff;
  .coding-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.thumbnail,
.avatar {
  position: relative;
  text-align: center;
  img {
    left: 0;
    right: 0;
    margin: 0 auto;
    object-fit: contain;
  }
  input[type="file"] {
    display: none;
  }
  .upload {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    // &:after {
    //   content: "Choose Image";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background-color: #000;
    //   color: #fff;
    // }
  }
}
.avatar {
  width: 200px;
  height: 200px;
  overflow: hidden;
  padding: 20px;
  border-radius: 50%;
  img {
    // width: 300px;
    // height: 300px;
    // position: absolute;
  }
  .upload {
    width: 300px;
    height: 300px;
    padding: 5px;
  }
}

.thumbnail {
  width: 200px;
  height: 150px;
  margin: 5px 0 20px;
  // width: 300px;
  // height: 150px;
  img {
    width: 200px;
    height: 150px;
    object-fit: cover;
  }
  .upload {
    bottom: 0;
    // width: 300px;
    // height: 150px;
    width: 200px;
    height: 150px;
    display: block;
    border: 1px solid $darkgreen;
  }
}

#new-class,
#universe-publish {
  padding: 40px 0;

  .main-area {
    max-width: 600px;
    margin: 20px auto;
    background-color: #fff;
    color: $darkgreen;
    padding: 20px;
    @include flexvc;
    border-radius: 8px;

    h4 {
      margin: 0;
      text-align: center;
    }
    .field {
      text-align: center;
      margin-bottom: 20px;
    }
    .thumbnail {
      width: 100%;
      height: auto;
      font-weight: bold;
      img {
        width: 100%;
        object-fit: contain;
      }
      .upload {
        border: none;
        height: 50px;
      }
    }
  }
  button {
    width: auto;
    display: flex;
    margin-top: 5px;
  }
  .quill {
    border: 1px solid #ccc;
    display: block;
    margin: 5px;
  }
  .errors {
    text-align: center;
  }
}

#jetfuel,
#question {
  .main {
    .sidebar {
      padding: 0px;
      h2 {
        font-size: 3rem;
      }
      button {
        margin-bottom: 20px;
        display: block;
      }
    }
    .main-content {
      h1 {
        color: $darkgreen;
      }
      input {
        display: block;
        margin: 20px 0;
      }
    }
  }
  // color: #fff;
  .main {
    .main-content {
      a {
        color: $darkgreen;
      }
      // max-width: 600px;
      // margin: 0 auto;
      > div {
        background-color: #fff;
        margin-bottom: 20px;
      }
    }
  }
}

#jetfuel {
  .main-content {
    > div {
      > a {
        @include flexh;
      }
    }
  }
}

#progress-reviews {
  .sidebar {
    > div {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      margin: 40px 0 0;
      > div {
        margin: 10px 0;
        display: flex;
      }
      .search {
        width: 80%;
        padding: 10px 5px;
      }
    }
    ul {
      li {
        list-style: none;
        cursor: pointer;
        margin: 10px 0;
      }
    }
  }
  .main-content {
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
    }
    > div {
      margin-bottom: 20px;
    }
    textarea {
      width: 400px;
      height: 100px;
    }
    .project {
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      a {
        margin: 10px 0;
        display: block;
      }
    }
    .approved {
      width: 15px;
      height: 15px;
      background-color: rgb(80, 221, 15);
      background: radial-gradient(circle, #0bec3c, #11bd11);
      border-radius: 50%;
      display: inline-block;
      margin-left: 10px;
    }
    .approve {
      @include approve;
      width: 200px;
      margin-bottom: 20px;
    }
    .disapprove {
      @include todo;
      width: 200px;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    .pending {
      background-color: $orange;
      background: radial-gradient(circle, #e9dd33, $orange);
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: inline-block;
      margin-left: 5px;
    }
    .status {
      margin: 10px 0;
      h4 {
        margin: 10px 0;
      }
    }
  }
}
