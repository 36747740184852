#new-class {
  h4 {
    margin: 0;
    text-align: center;
  }
  .field {
    margin-bottom: 20px;
  }
  button {
    margin-top: 5px;
  }
  .quill {
    display: block;
    margin: 5px;
  }
  .errors {
    text-align: center;
  }
}
