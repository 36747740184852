@import "../../index.scss";

.main {
  .main-content {
    display: block;
  }
}

.toggleLang {
  a {
    display: inline-block;
    padding: 10px 10px 0 0;
    cursor: pointer;
  }
}
